<template>
    <div class="page-wrapper section-space--inner--120">
        <!--Projects section start-->
        <div class="project-section">
            <div class="container">
                <div class="row">

                    <div class="col-12 section-space--bottom--40">
                        <div class="project-image" v-for="project in singleProject" :key="project.id">
                            <img :src="`/${ project.image }`" class="img-fluid" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-4 col-12 section-space--bottom--30">
                        <div class="project-information">
                            <h3>{{ data.projectDetails.title }}</h3>
                            <ul v-for="(mainLists, index) in singleProject" :key="index">
                                <li v-for="(sublist, listIndex) in mainLists.lists" :key="listIndex">
                                    <strong>{{ sublist.label }}</strong>{{ sublist.desc }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                        <div class="project-details">
                            <h2 v-for="project in singleProject" :key="project.id">{{ project.title }}</h2>
                            <p v-for="project in singleProject" :key="project.id">{{ project.desc }}</p>
                        </div>
                    </div>

                    <!-- <div class="col-12">
                        <div class="row row-5 image-popup" v-for="(project, index) in singleProject" :key="index">
                            <div class="col-xl-3 col-lg-4 col-sm-6 col-12 section-space--top--10" v-for="(img, imageIndex) in project.gallery" :key="imageIndex">
                                <div class="gallery-item single-gallery-thumb">
                                    <img :src="`/${ img.image }`" class="img-fluid" alt="thumbnail">
                                    <span class="plus"></span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-12">
                        <div class="row row-5 image-popup">
                             
                            <div class="col-xl-3 col-lg-4 col-sm-6 col-12 section-space--top--10" v-for="(img, index) in imgs" :key="index">
                                <div class="gallery-item single-gallery-thumb"  @click="show(index)">
                                    <img :src="img" class="img-fluid" alt="thumbnail">
                                </div>
                            </div>
                        
                         <vue-gallery-slideshow :images="imgs" :index="index" @close="index = null"></vue-gallery-slideshow>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--Projects section end-->
    </div>
</template>

<script>
import data from '../../data/project.json'

export default {
    components: {
        VueGallerySlideshow
    },
    data () {
        return {
            imgs: [],
            index: null,
            data,
            singleProject: [],
        }
    },
    computed: {
        id() {
            return this.$route.params.id
        }
    },
    created() {
        this.getData()
        this.getGallery()
    },
    methods: {
        getData() {
            let data = this.data.projectDetailsMAndE_2.projectDetailsMainMAndE_2
            return this.singleProject = data.filter(item => {
                return item.id == this.id
            })
        },
        getGallery() {
            let projects  = this.data.projectDetailsMAndE_2.projectDetailsMainMAndE_2
            // console.log('Projects:' + projects)
            let project = projects.filter(item => {
                return item.id == this.id
            } )
            // console.log('Project:' + JSON.stringify(project))
            // this.imgs = project.gallery[0]
            // console.log(this.imgs)
            let gallery = project[0].gallery
            this.imgs = gallery.map(function (obj) {
                 return '/' + obj.image
            })
            // console.log(JSON.stringify(this.images))
        },

        show(index) {
            this.index = index
        }
    }
};
</script>